<template>
  <div class="login">
    <b-container>
      <b-row class="register-holder text-center" align-v="center">
        <b-col>
          <transition name="slide-fade">
            <b-container class="login-box shadow" v-show="showForm">
              <div class="wrapper">
                <div class="image">
                  <img src="../assets/circular.svg" alt="circular" />
                </div>
                <div class="text-content">
                  <!-- <span class="tagline ml-3">{{ $t("home.welcome") }}</span> -->
                  <img src="../assets/eos.png" alt="circular" />

                  <h1 class="text-uppercase">{{ $t("register.register") }}</h1>
                </div>
              </div>

              <b-container class="mt-4">
                <b-form @submit.prevent="onSubmit" class="text-left">
                  <b-form-group
                    :label="$t('register.firstName')"
                    label-for="first-name"
                    class="user-input"
                  >
                    <b-form-input
                      id="first-name"
                      v-model="form.firstName"
                      type="text"
                      :placeholder="$t('register.firstName')"
                      size="lg"
                      required
                      trim
                      autofocus
                      :disabled="autofillBlocker"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    :label="$t('register.lastName')"
                    label-for="last-name"
                    class="user-input"
                  >
                    <b-form-input
                      id="last-name"
                      v-model="form.lastName"
                      type="text"
                      :placeholder="$t('register.lastName')"
                      size="lg"
                      required
                      trim
                      :disabled="autofillBlocker"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    :label="$t('register.email')"
                    label-for="email"
                    class="user-input"
                  >
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      type="email"
                      :placeholder="$t('register.email')"
                      size="lg"
                      required
                      trim
                      :disabled="autofillBlocker"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    :label="$t('register.password')"
                    label-for="password"
                    class="user-input"
                  >
                    <b-form-input
                      id="password"
                      v-model="form.password"
                      type="password"
                      :placeholder="$t('register.password')"
                      size="lg"
                      required
                      trim
                      :disabled="autofillBlocker"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    :label="$t('register.passwordRepeat')"
                    label-for="email"
                    class="user-input"
                  >
                    <b-form-input
                      id="password2"
                      v-model="form.password2"
                      type="password"
                      :placeholder="$t('register.passwordRepeat')"
                      size="lg"
                      required
                      trim
                      :disabled="autofillBlocker"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    :label="$t('register.deviceKey')"
                    label-for="deviceKey"
                    class="user-input"
                  >
                    <b-form-input
                      id="deviceKey"
                      v-model="form.deviceKey"
                      type="text"
                      :placeholder="$t('register.deviceKey')"
                      size="lg"
                      required
                      trim
                      :disabled="autofillBlocker"
                    ></b-form-input>
                  </b-form-group>
                  <div
                    class="
                      d-flex
                      justify-content-center
                      align-items-center
                      flex-column
                    "
                  >
                    <b-button
                      type="submit"
                      class="login-button"
                      size="lg"
                      variant="primary"
                      >{{ $t("register.signUp") }}</b-button
                    >
                    <router-link :to="{ name: 'Login' }" class="mt-2">{{
                      $t("register.LoginInstead")
                    }}</router-link>
                  </div>
                </b-form>
              </b-container>
            </b-container>
          </transition>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
// @ is an alias to /src
import { /*mapGetters,*/ mapActions } from "vuex";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "Register",
  data: () => {
    return {
      form: {},
      showForm: false,
      startTime: moment(),
      autofillBlocker: true,
      bootstrapDialog: Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }),
    };
  },
  methods: {
    ...mapActions("alerts", ["fireAlert"]),
    onSubmit() {
      // set language parameter for email language
      this.form.lang = this.$i18n.locale;

      axios
        .post("/ui-api/v1/register", this.form)
        .then(() => {
          //registration successful:
          this.bootstrapDialog
            .fire({
              title: this.$t("register.Congratulations"),
              text: this.$t("register.registeredInfo"),
              icon: "success",
              confirmButtonText: "OK",
            })
            .then(() => {
              // return to Home screen.
              this.$router.push({ name: "Home" });
            });
        })
        .catch((err) => {
          console.error(err.response);
          if (err.response.status == 404) {
            this.bootstrapDialog.fire({
              title: this.$t("register.oops"),
              text: this.$t("register.invalidDeviceKey"),
              icon: "error",
              confirmButtonText: "OK",
            });
          }
          else if (err.response.data.error == "Exists") {
            this.bootstrapDialog.fire({
              title: this.$t("register.oops"),
              text: this.$t("register.userExists"),
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            //registration unsuccessful:
            this.bootstrapDialog.fire({
              title: this.$t("register.oops"),
              text: this.$t("register.errorInfo"),
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.showForm = true;
    }, 500);
    setTimeout(() => {
      this.autofillBlocker = false;
    }, 810);
  },
};
</script>
<style lang="scss" scoped>
@import "../theme.scss";
.register-holder {
  height: calc(100vh - #{$navbar-height});
}
.login::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background-image: url("~@/assets/photovoltaic-bg.jpg");
  width: 100%;
  height: 100%;

  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.login-logo {
  max-height: 20vh;
}
.login-box {
  max-width: 75vw;
  background: $white;
  padding: 1rem;
  border-radius: 1rem;
}
.user-input {
  text-align: left;
  max-width: 75%;
  margin: 10px auto;
}
@include media-breakpoint-down(sm) {
  .user-input {
    max-width: 100%;
  }
}

.login-button {
  padding: 1rem 5rem;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  .image {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
  .text-content {
    margin-left: 1rem;
    flex-grow: 2;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    * {
      margin-bottom: 0.5rem;
    }

    img {
      height: 70px;
    }

    .tagline {
      font-family: "leadfont";
      text-transform: uppercase;
    }
  }
}
</style>
<style scoped>
</style>